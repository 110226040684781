import { useContext } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { HistoryContext } from "../contexts/HistoryContext";
import LoginScreen, { ForgotPassword, RegisterScreen, WelcomeScreen } from "./LoginScreen";
import Home from "./pages/Home";
import Orders from "./pages/Orders";
import Account from "./pages/Account";
import Wallet from "./pages/Wallet";
import Dish from "./pages/Dish";
import { StorageContext } from "../contexts/StorageContext";
import BasketPopup from "./Basket";
import Notifications from "./pages/Notifications";
import NavBar from "./NavBar";
import ConfirmationPage from "./confirmationModal";
import Addons from "./pages/Addons";
import { Loading } from "./Loading";

export const WELCOME_PAGES = [
    "WELCOME",
    "LOGIN",
    "REGISTER",
    "FORGOT",
    "LOADING"
]


export default function AppView() {

    const [history, setHistory] = useContext(HistoryContext);

    const page = history[history.length - 1];

    const pages = {
        "LOADING": Loading,
        "HOME": Home,
        "ORDERS": Orders,
        "ACCOUNT": Account,
        "WELCOME": WelcomeScreen,
        "LOGIN": LoginScreen,
        "REGISTER": RegisterScreen,
        "WALLET": Wallet,
        "DISH": Dish,
        "NOTIFICATIONS": Notifications,
        "CONFIRMATION": ConfirmationPage,
        "ADDONS": Addons,
        "FORGOT": ForgotPassword
    }

    const pageStrip = [
        "HOME",
        "ORDERS",
        "WALLET",
        "NOTIFICATIONS",
    ]

    const CurrentPage: any = pages[page.page as keyof typeof pages];

    const pageInPageStrip = pageStrip.includes(page.page);

    return (
        <>
            {WELCOME_PAGES.includes(page.page) || pageStrip.map((pageName, index) => {
                const Page: any = pages[pageName as keyof typeof pages];
                return <View key={index} style={[styles.page, { display: page.page === pageName ? "flex" : "none" }]}><Page /></View>
            })}
            <BasketPopup />
            {!pageInPageStrip && <View style={styles.pageAbsolute}>
                <CurrentPage style={styles.page} />
            </View>}
            {pageInPageStrip && <NavBar />}
        </>
    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        position: "relative"
    },
    pageAbsolute: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "white",
        display: "flex",
    },
});