import { Children, ReactNode, useContext } from "react";
import { Alert, Modal, StyleSheet, Text, Touchable, TouchableOpacity, View, Platform, Dimensions } from "react-native";
import { StorageContext } from "../contexts/StorageContext";

export default function ModalOpen(props: {
    modal: boolean,
    setModal: (modal: boolean) => void,
    children: ReactNode
}) {

    const { modal, setModal, children } = props;

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={modal}
            onRequestClose={() => {
                Alert.alert("Modal has been closed.");
                setModal(!modal);
            }}
        >
            <View style={styles.modal}>
                <View style={styles.wrapper}>
                    {children}
                </View>
            </View>
        </Modal>
    )
}

export function ModalSlide(props: {}) {
    const [storage, setStorage] = useContext(StorageContext);
    if (typeof storage?.modal === "undefined" || !storage.modal) return null;
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={!!storage.modal}
            onRequestClose={() => {
                setStorage({ ...storage, modal: null });
            }}
            statusBarTranslucent={true}
        >
            <View style={styles.sliderModal}>
                <TouchableOpacity
                    style={{ flex: 1, width: "100%" }}
                    onPress={() => setStorage({ ...storage, modal: null })}
                />
                <View style={styles.sliderModalWrapper}>
                    {storage.modal}
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    modal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    sliderModal: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    wrapper: {
        backgroundColor: "#171717",
        padding: 20,
        borderRadius: 10,
        width: "80%",
    },
    sliderModalWrapper: {
        backgroundColor: "white",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        width: "100%",
        maxHeight: "90%",
    },
});