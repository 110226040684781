import { StyleSheet, View } from "react-native";
import React, { useContext } from "react";
import NavBarButton from "./navbar/Buttons";
import { StorageContext } from "../contexts/StorageContext";
export default function NavBar() {

    const [storage, setStorage] = useContext(StorageContext);
    const notifications = storage?.notifications;

    const buttons = [
        { name: "Eat", link: "HOME", icon: "md-fast-food" },
        { name: "Basket", link: "ORDERS", icon: "basket" },
        { name: "Wallet", link: "WALLET", icon: "wallet" },
        { name: "Notifications", link: "NOTIFICATIONS", icon: "notifications", badge: notifications },
        //{ name: "My Account", link: "CONFIRMATION", icon: "person-circle" },
    ]

    return (
        <View style={styles.navBar}>
            {buttons.map((button, index) =>
                <NavBarButton key={index} {...button} />
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    navBar: {
        flexShrink: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        zIndex: 200,
    },
});