import moment from "moment";
import { useContext } from "react";
import { Image, Text, View } from "react-native";
import { HistoryContext } from "../../contexts/HistoryContext";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import { DishType } from "../../types/vendors";
import { priceStrip } from "../../utils/BaseUtils";
import Header from "../navbar/Header";
import { getCdnImage } from "../../utils/cdn";

export default function Dish() {

    const [history, setHistory] = useContext(HistoryContext);
    const currentPage = history[history.length - 1];
    const dish: DishType = currentPage.meta;
    return (
        <View style={[pageStyles.page, { padding: 0 }]}>
            <Header
                title={dish.name}
                leftIcon="arrow-back-outline"
                leftIconPress={() => setHistory(history.slice(0, history.length - 1))}
            />
            <View style={[pageStyles.page, { marginTop: 0 }]}>
                <Image
                    source={{
                        uri: typeof dish.image != "undefined" && dish.image !== "" && dish.image !== " " ? getCdnImage(dish.image, 400) : "https://assets.materialup.com/uploads/98622f57-ac59-4b44-8db2-3355bb43efed/preview.jpg",
                    }}
                    style={{
                        width: "100%",
                        aspectRatio: 1,
                        borderRadius: 20
                    }}
                />
                <View style={{ marginTop: 10 }}>
                    <Text style={txtStyles.title}>{dish.name}</Text>
                    <Text style={txtStyles.base}>{dish.description}</Text>
                    <Text style={[txtStyles.base, { marginTop: 30 }]}>
                        Ready by ~{moment().add(dish.ready_in, 'minutes').format('h:mm a')} ({dish.ready_in} mins)
                    </Text>
                    <Text style={[txtStyles.largeText, { marginTop: 20 }]}>&#8377; {priceStrip(dish.price)}</Text>
                </View>
            </View>
        </View>

    )
}