import { UserType } from "./usertypes"
import { DishType } from "./vendors"

export enum EntityType {
    ORDER = 1,
    WALLET = 2,
}

export type OrderType = {
    external_id: string,
    user: UserType,
    dishes: DishType[],
    transaction_type: number,
    created_at: string,
    is_delivered: boolean,
    payment_status: boolean,
    payment_method: string,
    order_token: string,
    amount: number,
    rejected: boolean,
    cancelled: boolean,
    ready: boolean,
    placed_at: string,
    delivered_at: string,
    rejected_at: string,
    cancelled_at: string,
    ready_at: string,
}