export enum NotificationTypeEnum {
    WALLET_CREDIT = 1,
    WALLET_DEBIT = 2,
    ORDER_PLACED = 3,
    ORDER_CANCELLED = 4,
    ORDER_REJECTED = 5,
    ORDER_DELIVERED = 6,
    ORDER_READY = 7,
}

export type NotificationType = {
    external_id: string,
    type: NotificationTypeEnum,
    read: boolean,
    opened: boolean,
    created_at: string,
    content?: any
}