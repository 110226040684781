import AsyncStorage from "@react-native-async-storage/async-storage";
import { StorageType } from "../contexts/StorageContext";
import { EntityType } from "../types/ordertypes";
import { UserType } from "../types/usertypes";
import { API_URL } from "@env";

export const API_BASE_URL = API_URL || "https://api.bavrchi.com/v1/";

type endpoint = `${string}`;

export type paginatedResponse<T> = {
    count: number,
    has_next: boolean,
    has_previous: boolean,
    offset: number,
    results: T[],
}

type methods = "POST" | "GET" | "PATCH" | "DELETE" | "PUT";

type options = {
    formdata?: boolean,
    external?: boolean,
    headers?: any,
    auth?: boolean,
}

const request = async (endpoint: endpoint, method: methods = "GET", data: any = {}, options: options = {}) => {

    const { formdata, external, headers, auth: isAuth } = options;

    let url = !!external ? endpoint : (API_BASE_URL + endpoint);
    let payload: null | string = !!formdata ? data : JSON.stringify(data);

    if (method === "GET") {
        const requestParams = data ? `?${Object.keys(data).map(key => `${key}=${typeof data[key] === "undefined" ? "" : data[key]}`).join("&")}` : "";
        url += requestParams;
        payload = null;
    }
    const storage: StorageType = JSON.parse(await AsyncStorage.getItem("storage"));
    const localToken = storage.auth_token;

    const auth = isAuth === false || typeof localToken === "undefined" || localToken === null ? "" : "Token " + localToken;

    const response = await fetch(url, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: auth,
            ...headers
        },
        body: payload
    });
    try {
        const txt = await response.clone().text();
        if (txt === "") {
            return {};
        }
        const json = await response.clone().json();
        if (json && response.ok) {
            return json;
        } else {
            throw json;
        }
    } catch (error) {
        throw { error };
    }
}

export const API = {
    user: {
        login: (email: string, password: string) => request("auth/login", "POST", { email, password }),
        me: () => request("users/me"),
        save: (details: UserType) => request(`users/me`, "PATCH", details),
        register: (details: {
            email: string,
            password: string,
            full_name: string,
            username: string,
        }) => request(`auth/register`, "POST", details, { auth: false }),
    },
    wallet: {
        transaction: {
            list: (offset: number = 0) => request("users/me/wallet-transactions", "GET", { ordering: "-created_at", offset }),
            get: (transactionId: string) => request(`users/me/wallet-transactions/${transactionId}`),
            create: (amount: number) => request(`users/me/wallet-transactions`, "POST", { amount }),
        }
    },
    vendors: {
        list: () => request("vendors"),
        get: (vendorID: string) => request(`vendors/${vendorID}`),
    },
    dishes: {
        list: (vendorId: string, filters: { meal?: boolean, addon?: boolean, offset?: number } = { meal: false, addon: false }) => request(`vendors/${vendorId}/dishes`, "GET", filters),
        get: (vendorId: string, dishId: string) => request(`vendors/${vendorId}/dishes/${dishId}`),
    },
    transactions: {
        create: (entity_id: string, entity_type: EntityType) => request(`transactions`, "POST", { entity_id, entity_type }),
        get: (transactionId: string) => request(`transactions/${transactionId}`),
    },
    orders: {
        list: (offset: number = 0, payment_status?: boolean, is_delivered?: boolean, cancelled?: boolean, rejected?: boolean) => request("orders", "GET", { ordering: "-created_at", offset, payment_status, is_delivered, cancelled, rejected }),
        create: (order: { dishes: string[], transaction_type: number }) => request(`orders`, "POST", order),
    },
    notifications: {
        list: (offset?: number) => request("notifications", "GET", { ordering: "-created_at", offset }),
        see: () => request("notifications/see", "POST"),
        read: (notif: string) => request(`notifications/${notif}/read`, "POST"),
        createPushToken: (token: string) => request(`notifications/token`, "POST", { token }),
    },
    forgotPassword: {
        initiate: (email: string) => request(`forgot-password`, "POST", { email, url: "" }, { auth: false }),
        verify: (email: string, otp: string) => request(`forgot-password/verify`, "POST", { email, otp }, { auth: false }),
        reset: (email: string, otp: string, password: string) => request(`forgot-password/reset`, "POST", { email, otp, password }, { auth: false }),
    }
}
