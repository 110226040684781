import { useContext, useEffect, useRef } from "react";
import { Animated, StyleSheet, Text, Touchable, TouchableOpacity, View } from "react-native";
import { StorageContext } from "../contexts/StorageContext";
import { txtStyles } from "../styles/Text";
import { priceStrip } from "../utils/BaseUtils";
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { LinearGradient } from "expo-linear-gradient";
import { HistoryContext } from "../contexts/HistoryContext";

export default function BasketPopup() {
    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);
    const dishes = typeof storage?.basket !== "undefined" ? storage.basket : [];
    const totalCost = dishes.reduce((a, b) => parseFloat(`${a}`) + parseFloat(`${b.price}`), 0);
    const show = dishes.length > 0 && ["HOME", "ADDONS"].includes(history[history.length - 1].page);

    const animation = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animation, {
            toValue: show ? 0 : 150,
            duration: 100,
            useNativeDriver: true,
        }).start();
    }, [show]);

    const styles = StyleSheet.create({
        popup: {
            position: "absolute",
            bottom: 65,
            right: 15,
            left: 15,
            padding: 15,
            borderRadius: 10,
            zIndex: 101,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },
        base: {
            color: "white",
            fontFamily: "Manrope"
        }
    })

    return (

        <Animated.View style={[styles.popup, {
            transform: [{ translateY: animation }],
        }]}>
            <LinearGradient
                colors={["orange", "#ff7b00"]}
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    borderRadius: 10,
                }}
            />
            <View>
                <Text style={[styles.base, { fontSize: 12 }]}>
                    {dishes.length} dish{dishes.length !== 1 && "es"} selected.
                </Text>
                <Text style={styles.base}>
                    Total: &#8377; {priceStrip(totalCost)}
                </Text>
            </View>
            <TouchableOpacity onPress={() => setHistory([...history, { page: "ORDERS" }])} activeOpacity={0.8} style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Text style={[styles.base, { fontFamily: "manropebold" }]}>
                    View Basket
                </Text>
                <Ionicons name={"arrow-forward-circle"} style={{ fontSize: 20, marginLeft: 10, color: "white" }} />
            </TouchableOpacity>
        </Animated.View>
    )
}