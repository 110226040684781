export type UserType = {
    external_id: string;
    username: string;
    full_name: string;
    email: string;
    wallet_balance: number;
}

export enum WalletTransactionEnum {
    DEBIT = 1,
    CREDIT = 2
}

export type WalletTransactionType = {
    external_id: string;
    type: WalletTransactionEnum;
    amount: number;
    order?: any;
    created_at: string;
}