import { StyleSheet } from "react-native";

export const txtStyles = StyleSheet.create({
    title: {
        fontSize: 30,
        //fontWeight: "bold",
        color: "black",
        fontFamily: "manropebold"
    },
    base: {
        color: "black",
        fontFamily: "Manrope"
    },
    largeText: {
        fontSize: 20,
        fontFamily: "manropebold"
    },
    link: {
        color: "orange",
    }
});