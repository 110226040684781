import { FlatList, ScrollView, Text, TouchableOpacity, View, Linking, TextInput, StyleSheet, Platform } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import { useContext, useEffect, useState } from "react";
import { HistoryContext } from "../../contexts/HistoryContext";
import Icon from "../Icon";
import { StorageContext } from "../../contexts/StorageContext";
import { Dish } from "./Home";
import { priceStrip } from "../../utils/BaseUtils";
import moment, { Moment } from "moment";
import { DishType } from "../../types/vendors";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import QuantityInput from "../Quantity";
import { API } from "../../utils/api";
import { useQuery } from "react-query";
import { EntityType, OrderType } from "../../types/ordertypes";
import QRCode from "react-native-qrcode-svg";
import LottieView from "lottie-react-native";
import Loader from "../Loading";
import UpiInput from "../UpiInput";

export default function Orders() {

    const [history, setHistory] = useContext(HistoryContext);
    const [storage, setStorage] = useContext(StorageContext);
    const [upiId, setUpiId] = useState<string>(storage.upiId || "");
    const [paymentMethod, setPaymentMethod] = useState<number | null>(null);
    const basket = typeof storage?.basket !== "undefined" ? storage.basket : [];
    const totalCost = basket.reduce((a, b) => parseFloat(`${a}`) + parseFloat(`${b.price}`), 0);
    const totalReady = Math.max(...(basket.map((b) => b.ready_in)));
    const uniqueBasket = basket.filter((b, i, a) => a.findIndex((t) => t.name === b.name) === i);
    const walletBalance = storage?.user?.wallet_balance;
    const walletAfterBalance = walletBalance - parseInt(priceStrip(totalCost));
    const checkoutDisabled = !paymentMethod || (paymentMethod === 2 && walletAfterBalance < 0);

    const ordersQuery = useQuery("undelivered_orders", async () => await API.orders.list(0, true, false, false, false), {
        onSuccess: (data) => {
            //console.log(data);
        },
        refetchInterval: 20000,
    });

    const paymentMethods = [
        /*{
            name: "UPI",
            icon: "rupee",
            id: 1,
            body: (
                <View style={{ marginTop: 20 }}>
                    <Text style={[txtStyles.base, { color: "grey", marginBottom: 10 }]}>
                        Paying using UPI
                    </Text>
                    <UpiInput value={upiId} onChange={newText => setUpiId(newText)} />
                </View>
            )
        },*/
        {
            name: "Wallet",
            icon: "wallet",
            id: 2,
            body: (
                <View style={{ marginTop: 20 }}>
                    <Text style={[txtStyles.base, { color: "grey" }]}>
                        Paying using wallet
                    </Text>
                    <View style={{ display: "flex", flexDirection: "row", borderBottomColor: "#e1e1e1", paddingVertical: 10, justifyContent: "space-between" }}>
                        <Text style={[txtStyles.base]}>
                            Wallet balance
                        </Text>
                        <Text style={[txtStyles.base, { color: "grey" }]}>
                            &#8377; {storage?.user?.wallet_balance}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", borderBottomColor: "#e1e1e1", borderBottomWidth: 1, paddingVertical: 10, justifyContent: "space-between" }}>
                        <Text style={[txtStyles.base]}>
                            To pay
                        </Text>
                        <Text style={[txtStyles.base, { color: "grey" }]}>
                            - &#8377; {priceStrip(totalCost)}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", borderBottomColor: "#e1e1e1", borderBottomWidth: 1, paddingVertical: 10, justifyContent: "space-between" }}>
                        <Text style={[txtStyles.base, { color: walletAfterBalance < 0 ? "red" : "black" }]}>
                            Wallet balance after payment
                        </Text>
                        <Text style={[txtStyles.base, { color: walletAfterBalance < 0 ? "red" : "gray" }]}>
                            &#8377; {walletAfterBalance}
                        </Text>
                    </View>
                    {walletAfterBalance < 0 && (
                        <View style={{ marginTop: 20 }}>
                            <Text style={[txtStyles.base, { color: "red", fontSize: 12 }]}>
                                <Ionicons name="alert-circle" size={12} color="red" />
                                &nbsp;
                                You don't have enough balance in your wallet to pay for this order.
                            </Text>
                        </View>
                    )}
                </View>
            )
        },
        {
            name: "Online",
            icon: "rupee",
            id: 1,
            body: (
                <View style={{ marginTop: 20 }}>
                </View>
            )
        }
    ]

    const handleCheckout = async () => {
        console.log("Checkout", paymentMethod);
        switch (paymentMethod) {
            case 1: {
                setHistory([...history, {
                    page: "CONFIRMATION",
                    meta: {
                        paymentMethod: "UPI",
                        upi_id: upiId,
                        entityType: EntityType.ORDER,
                    }
                }])
                break;
            }
            case 2: {
                setHistory([...history, {
                    page: "CONFIRMATION"
                }])
                break;
            }

            default:
                break;
        }
    }

    return (
        <View style={{ justifyContent: "space-between", display: "flex", flex: 1 }}>
            <ScrollView
                contentContainerStyle={[pageStyles.page, { paddingBottom: 100 }]}
                refreshControl={
                    <RefreshControl
                        refreshing={ordersQuery.isLoading}
                        onRefresh={() => ordersQuery.refetch()}
                    />
                }

            >
                <Text style={[txtStyles.title, {}]}>
                    Basket
                </Text>
                {ordersQuery.isSuccess && ordersQuery.data.count > 0 && <OrderView orders={ordersQuery.data.results} title={true} />}
                <View style={{ marginTop: 20, paddingBottom: 10, borderBottomColor: "#e1e1e1", borderBottomWidth: 1 }}>
                    {uniqueBasket.map((dish: DishType, index: number) => {
                        return (
                            <View
                                key={index}
                                style={{
                                    borderWidth: 1,
                                    borderRadius: 15,
                                    borderColor: "#f1f1f1",
                                    backgroundColor: "#f1f1f1",
                                    padding: 15,
                                    marginBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <View>
                                    <Text style={[txtStyles.base, { fontFamily: "manropebold" }]}>
                                        {dish.name}
                                    </Text>
                                    <Text style={[txtStyles.base, { color: "grey" }]}>
                                        &#8377; {priceStrip(basket.filter(p => p.external_id === dish.external_id).reduce((a, b) => parseFloat(`${a}`) + parseFloat(`${b.price}`), 0))} {basket.filter(d => d.external_id === dish.external_id).length > 1 && <>({priceStrip(dish.price)} each)</>}
                                    </Text>
                                </View>

                                <View style={{ width: 100 }}>
                                    <QuantityInput
                                        quantity={storage.basket.filter((d) => d.external_id === dish.external_id).length}
                                        onAdd={() => {
                                            let basket: DishType[] = [];
                                            if (typeof storage.basket !== "undefined" && storage.basket) {
                                                basket = storage.basket;
                                            }
                                            typeof storage !== "undefined" && setStorage({
                                                ...storage,
                                                basket: [...basket, dish]
                                            })
                                        }}
                                        onRemove={() => {
                                            let basket: DishType[] = [];
                                            if (typeof storage.basket !== "undefined" && storage.basket) {
                                                basket = storage.basket;
                                            }
                                            const index = basket.findIndex((d) => d.external_id === dish.external_id);
                                            basket.splice(index, 1);
                                            typeof storage !== "undefined" && setStorage({
                                                ...storage,
                                                basket: [...basket]
                                            })
                                        }}
                                        max={5}
                                    />
                                </View>
                            </View>
                        )
                    })}
                </View>
                {basket.length > 0 ? (<>
                    <Text style={[txtStyles.largeText, { marginTop: 20 }]}>
                        Payment method
                    </Text>
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginTop: 10,
                    }}>
                        {paymentMethods.map((method, index) => {
                            return (
                                <TouchableOpacity
                                    key={index}
                                    style={{
                                        borderWidth: 1,
                                        borderRadius: 15,
                                        borderColor: paymentMethod === method.id ? "orange" : "#f1f1f1",
                                        backgroundColor: paymentMethod === method.id ? "orange" : "#f1f1f1",
                                        padding: 15,
                                        marginTop: 10,
                                        width: "49%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onPress={() => setPaymentMethod(method.id)}
                                >
                                    {method.icon === "rupee" ? <FontAwesome name="rupee" size={20} color={paymentMethod === method.id ? "white" : "black"} /> : <Ionicons name={method.icon as any} size={20} color={paymentMethod === method.id ? "white" : "black"} />}
                                    <Text style={[txtStyles.base, { marginLeft: 10, color: paymentMethod === method.id ? "white" : "black" }]}>
                                        {method.name}
                                    </Text>
                                </TouchableOpacity>
                            )
                        })}
                    </View>
                    <View>
                        {typeof paymentMethod === "undefined" || paymentMethods.find((p) => p.id === paymentMethod)?.body}
                    </View>
                </>) : <>
                    <View style={{ flex: 1, justifyContent: "center", display: "flex", alignItems: "center" }}>
                        <Text style={[{ width: 300, textAlign: "center", marginTop: 30 }, txtStyles.base]}>
                            Your basket is empty. Add some dishes to see them here.
                        </Text>
                    </View>
                </>}
            </ScrollView>
            {basket.length > 0 && (<>
                <View style={{
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    shadowColor: "#000",
                    shadowOffset: {
                        width: 0,
                        height: -5,
                    },
                    shadowOpacity: 0.1,
                    shadowRadius: 10,
                    elevation: 10,
                    backgroundColor: "white",
                    padding: 20,
                    paddingBottom: 10,
                }}>
                    <Text style={[txtStyles.base, { marginBottom: 10 }]}>
                        To pay : &#8377; {priceStrip(totalCost)} &bull; {basket.length} dish{basket.length !== 1 && "es"} &bull; ready by ~ {moment().add(totalReady, "minutes").format("h:mm a")}
                    </Text>
                    <TouchableOpacity
                        disabled={checkoutDisabled}
                        style={[pageStyles.button, { backgroundColor: !checkoutDisabled ? "orange" : "gray" }]}
                        onPress={handleCheckout}
                    >
                        <Text style={[pageStyles.buttonText]}>
                            Checkout
                        </Text>
                    </TouchableOpacity>
                </View>
            </>)}
        </View>
    )
}

export function OrderView(props: { orders: OrderType[], title?: boolean }) {
    const { orders, title } = props;
    const [storage, setStorage] = useContext(StorageContext);
    const [time, setTime] = useState<Moment>(moment());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(moment());
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);
    return (
        <View style={{ marginTop: 10, paddingBottom: 10, borderBottomColor: "#e1e1e1", borderBottomWidth: 1 }}>
            {title === true && <Text style={[txtStyles.largeText, { marginBottom: 10 }]}>
                Orders on the way
            </Text>}
            {orders.map((order: OrderType, index: number) => {
                const prep_time = Math.max(...order.dishes.map((dish) => dish.ready_in));
                const time_since_order = time.diff(moment(order.placed_at), "seconds");
                const raw_percentage = Math.min(time_since_order / (prep_time * 60), 1) * 100;
                const percentage = Math.round(raw_percentage);
                const uniqueDishes = order.dishes.filter((b, i, a) => a.findIndex((t) => t.external_id === b.external_id) === i);
                let bg = "#f1f1f1";
                let fg = "black";
                let progress = null;
                if (order.is_delivered) {
                    bg = "dodgerblue";
                    fg = "white";
                    progress = "delivered";
                }
                else if (order.cancelled || order.rejected) {
                    bg = "gray"
                    fg = "white";
                    progress = order.cancelled ? "cancelled" : "rejected by vendor";
                }
                else if (order.ready) {
                    bg = "orange";
                    fg = "white";
                    progress = "READY TO PICK UP";
                }
                return (
                    <TouchableOpacity
                        onPress={() => {
                            setStorage({
                                ...storage,
                                modal: <View style={{ padding: 20, display: "flex", alignItems: "center" }}>
                                    <Text style={[txtStyles.largeText, { marginBottom: 10 }]}>
                                        Order details
                                    </Text>
                                    <Text style={[txtStyles.base, { marginBottom: 30, textAlign: "center" }]}>
                                        {uniqueDishes.map((dish) => dish.name + (order.dishes.filter(d => dish.external_id === d.external_id).length > 1 ? `(${order.dishes.filter(d => dish.external_id === d.external_id).length}) ` : "")).join(", ")}
                                    </Text>
                                    {order.is_delivered ? (
                                        <>
                                            <Text style={[txtStyles.base, { marginBottom: 10 }]}>
                                                Delivered at {moment(order.delivered_at).format("h:mm a, Do MMMM YYYY")}
                                            </Text>
                                        </>
                                    ) : order.ready ? <>
                                        <QRCode
                                            value={order.order_token}
                                            size={250}
                                        />
                                        <View
                                            style={{
                                                marginTop: 20,
                                            }}
                                        />
                                        <Text style={[txtStyles.largeText]}>
                                            {order.order_token}
                                        </Text>
                                        <Text style={[txtStyles.base, { marginTop: 20, width: 200, textAlign: "center", fontFamily: "manropebold" }]}>
                                            Show this QR code on the counter to collect your order.
                                        </Text>
                                    </> : <>
                                        {Platform.OS !== "web" &&
                                            <LottieView
                                                style={[{
                                                    width: 200,
                                                    height: 200,
                                                }]}
                                                source={require('../../animations/food-prepare.json')}
                                                autoPlay
                                                loop={true}
                                            />
                                        }
                                        <Text style={[txtStyles.base, { width: 200, textAlign: "center", fontFamily: "manropebold" }]}>
                                            Your order is being prepared.
                                        </Text>
                                    </>}
                                </View>

                            })
                        }}
                        key={index}
                        style={{
                            backgroundColor: bg,

                            borderRadius: 10,
                            marginBottom: 10,
                            overflow: "hidden",
                        }}
                    >
                        {order.cancelled || order.rejected || order.is_delivered || <View style={[styles.progress, { width: `${percentage}%` }]} />}
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: 20,
                                paddingTop: (order.cancelled || order.rejected || order.is_delivered) ? 20 : 15,
                            }}
                        >
                            <View style={{ flex: 1 }}>
                                <Text style={[txtStyles.base, { color: fg, fontFamily: "manropebold" }]}>
                                    {uniqueDishes.map((dish) => dish.name + (order.dishes.filter(d => dish.external_id === d.external_id).length > 1 ? `(${order.dishes.filter(d => dish.external_id === d.external_id).length}) ` : "")).join(", ")}
                                </Text>
                            </View>

                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexShrink: 0,
                                    marginLeft: 10,
                                    opacity: 1
                                }}
                            >
                                <Text style={[txtStyles.base, { fontSize: 12, color: fg }]}>
                                    {progress || (percentage < 100 ? `preparing` : 'ready soon')}
                                </Text>
                                <Icon name="chevron-forward" style={[{ marginLeft: 10, color: fg }]} />
                            </View>
                        </View>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    progress: {
        height: 5,
        backgroundColor: "orange",
    }
})