import { useContext, useState } from "react";
import { FlatList, Image, StyleSheet, Text, TouchableOpacity, View, Platform, ScrollView, ImageBackground, Linking } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { HistoryContext } from "../../contexts/HistoryContext";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import { StorageContext } from "../../contexts/StorageContext";
import { useQuery, useQueryClient } from "react-query";
import { API } from "../../utils/api";
import { DishType, VendorType } from "../../types/vendors";
import { priceStrip } from "../../utils/BaseUtils";
import moment from "moment";
import QuantityInput from "../Quantity";
import { Ionicons } from '@expo/vector-icons';
import { PageHeader } from "../navbar/Header";
import { LinearGradient } from "expo-linear-gradient";
import { PLAYSTORE_URL } from "@env";
import { getCdnImage } from "../../utils/cdn";

export default function Home() {

    const [history, setHistory] = useContext(HistoryContext);
    const [storage, setStorage] = useContext(StorageContext);
    const [scrolled, setScrolled] = useState(0);
    const queryClient = useQueryClient();

    const vendorsQuery = useQuery("vendors", async () => await API.vendors.list());
    const vendorId = vendorsQuery?.data?.results?.[0]?.external_id;
    const vendor: VendorType = vendorsQuery?.data?.results?.[0];
    let cts = [0, 0, 0];
    let ots = [0, 0, 0];
    if (typeof vendor !== "undefined") {
        cts = vendor?.closes_at.split(":").map((x) => parseInt(x));
        ots = vendor?.opens_at.split(":").map((x) => parseInt(x));
    }
    const ct = new Date().setHours(cts[0], cts[1], 0, 0);
    const ot = new Date().setHours(ots[0], ots[1], 0, 0);
    const currentTime = new Date().getTime();
    const isOpen: boolean = !vendor?.is_closed && currentTime > ot && currentTime < ct;
    const dishQuery = useQuery(["dishes", vendorId], async () => await API.dishes.list(vendorId), {
        enabled: !!vendorId && isOpen,
    });

    const mealQuery = useQuery("meals", async () => await API.dishes.list(vendorId, { meal: true }), {
        enabled: !!vendorId && isOpen,
    });

    const handleRefresh = async () => {
        await queryClient.invalidateQueries(["dishes", vendorId]);
        await vendorsQuery.refetch();
        await dishQuery.refetch();
        await mealQuery.refetch();
    }

    if (vendorsQuery.isLoading || dishQuery.isLoading)
        return (
            <View style={[pageStyles.page, { display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }]}>
                <Text>
                    Loading...
                </Text>
            </View>
        )

    return isOpen && vendorId ? (
        <>
            <PageHeader
                scrolled={scrolled}
                title={`Hello ${storage?.user?.full_name.split(" ")[0]}!`}
                rightElement={
                    <TouchableOpacity onPress={() => setHistory([...history, { page: "ACCOUNT" }])} >
                        <Ionicons name="person-circle" style={{ fontSize: 35 }} />
                    </TouchableOpacity>
                }
            />
            {/*<ImageBackground
                source={require("../../../assets/waves3.png")}
                style={{ width: "100%", flex: 1 }}
            >*/}
            <FlatList
                refreshControl={
                    <RefreshControl refreshing={vendorsQuery.isLoading || dishQuery.isLoading} onRefresh={handleRefresh} />
                }
                onScroll={(e) => {
                    const scrolled = e.nativeEvent.contentOffset.y;
                    setScrolled(scrolled);
                }}
                ListHeaderComponent={() => (
                    <>
                        <TouchableOpacity
                            onPress={() => Linking.openURL(`${PLAYSTORE_URL}`)}
                            style={{
                                padding: 20,
                                marginBottom: 20,
                                position: "relative",
                                overflow: "hidden",
                                display: "none"
                            }}
                        >
                            <LinearGradient
                                colors={["rgba(15, 150, 0,1)", "rgba(24, 245, 0,1)"]}
                                start={[0, 1]}
                                end={[1, 0]}
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    borderRadius: 10,
                                    zIndex: -1
                                }}
                            />
                            <Text
                                style={[txtStyles.largeText, { color: "white", fontFamily: "manropebold", marginRight: 100 }]}
                            >
                                It's better on the app
                            </Text>
                            <Text
                                style={[txtStyles.base, { color: "white", fontFamily: "manropebold", marginRight: 100, marginTop: 10 }]}
                            >
                                Download the Bavrchi App from the Play Store for a better experience
                            </Text>
                            <Image
                                source={require("../../../assets/layke.png")}
                                style={{ width: 90, height: 90, position: "absolute", right: 0, top: 10 }}
                            />
                        </TouchableOpacity>
                        <ScrollView
                            style={{ marginBottom: 20 }}
                            horizontal={true}
                        >
                            {mealQuery.isSuccess && mealQuery.data.results.map((meal: DishType, i: number) => (
                                <View
                                    key={i}
                                    style={{
                                        width: 200,
                                        height: 150,
                                        marginRight: 10,
                                        borderRadius: 10,
                                        overflow: "hidden",
                                        position: "relative",
                                        backgroundColor: "white",
                                        padding: 10,
                                        borderColor: "rgba(0,0,0,0.1)",
                                        borderWidth: 1,
                                    }}
                                >
                                    <View style={{ flex: 1 }}>
                                        <Text style={[txtStyles.largeText]}>{meal.name}</Text>
                                        <Text style={[txtStyles.base]}>
                                            &#8377; {priceStrip(meal.price)}
                                        </Text>
                                        <Text style={[txtStyles.base]}>
                                            {meal.meal_content?.join(", ")}
                                        </Text>
                                    </View>
                                    <DishAddButton dish={meal} />
                                </View>
                            ))}
                        </ScrollView>
                        <TouchableOpacity
                            onPress={() => setHistory([...history, { page: "ADDONS", meta: { vendor: vendorId } }])}
                            style={{
                                padding: 20,
                                marginBottom: 20,
                                position: "relative",
                                overflow: "hidden"
                            }}
                        >
                            <LinearGradient
                                colors={["rgba(0,46,176,1)", "rgba(0,212,255,1)"]}
                                start={[0, 1]}
                                end={[1, 0]}
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    borderRadius: 10,
                                    zIndex: -1
                                }}
                            />
                            <Text
                                style={[txtStyles.base, { color: "white", fontFamily: "manropebold", marginRight: 100 }]}
                            >
                                Upgrade your experience with an addon
                            </Text>
                            <Image
                                source={require("../../../assets/layke.png")}
                                style={{ width: 90, height: 90, position: "absolute", right: 0, top: 10 }}
                            />
                        </TouchableOpacity>
                    </>
                )}
                style={[pageStyles.page, { position: "relative", paddingTop: Platform.OS !== 'web' ? 110 : 50, marginTop: 0 }]}
                data={dishQuery?.data?.results}
                ListFooterComponent={() => (
                    <View style={{ height: 200 }} />
                )}
                renderItem={({ item }) => <Dish dish={item} />}
                keyExtractor={(item) => item.external_id}
                numColumns={2}
                columnWrapperStyle={{ justifyContent: "space-between" }}
            />
            {/*</ImageBackground>*/}
        </>
    ) : (
        <View style={[pageStyles.page, { display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }]}>
            <Image
                source={require("../../../assets/closed.png")}
                style={{ width: 300, height: 300 }}
            />
            <Text style={[{ width: 300, textAlign: "center" }, txtStyles.base]}>Orders can't be accepted at the moment, please try again later!</Text>
        </View>
    )
}

export const Dish = ({ dish, isAddon = false }: { dish: DishType, isAddon?: boolean }) => {
    return (
        <View style={[styles.heroBtn, { margin: "1%" }]}>
            <Image
                source={{ uri: dish.image ? getCdnImage(dish.image, 200) : "https://assets.materialup.com/uploads/98622f57-ac59-4b44-8db2-3355bb43efed/preview.jpg" }}
                style={{ width: "100%", aspectRatio: 1, borderRadius: 10 }}
                resizeMode={isAddon ? "contain" : "cover"}
            />
            <Text style={[txtStyles.base, { fontWeight: "bold", fontSize: 18, marginTop: 10 }]}>{dish.name}</Text>
            <Text style={[txtStyles.base, { fontSize: 15, marginTop: 5 }]}>&#8377; {priceStrip(dish.price)}</Text>
            {dish.no_prep || (<>
                <Text style={[txtStyles.base, { fontSize: 10, color: "gray", marginTop: 10 }]}>Ready by</Text>
                <Text style={[txtStyles.base, { fontSize: 12, color: "gray" }]}>~{moment().add(dish.ready_in, 'minutes').format('h:mm a')} ({dish.ready_in} mins)</Text>
            </>)}
            <DishAddButton dish={dish} />
        </View>
    )
}

export const DishAddButton = ({ dish }: { dish: DishType }) => {
    const [storage, setStorage] = useContext(StorageContext);
    return typeof storage.basket !== "undefined" && storage.basket && storage.basket.find((d) => d.external_id === dish.external_id) ? (
        <QuantityInput
            style={{ marginTop: 10 }}
            quantity={storage.basket.filter((d) => d.external_id === dish.external_id).length}
            onAdd={() => {
                let basket: DishType[] = [];
                if (typeof storage.basket !== "undefined" && storage.basket) {
                    basket = storage.basket;
                }
                typeof storage !== "undefined" && setStorage({
                    ...storage,
                    basket: [...basket, dish]
                })
            }}
            onRemove={() => {
                let basket: DishType[] = [];
                if (typeof storage.basket !== "undefined" && storage.basket) {
                    basket = storage.basket;
                }
                const index = basket.findIndex((d) => d.external_id === dish.external_id);
                basket.splice(index, 1);
                typeof storage !== "undefined" && setStorage({
                    ...storage,
                    basket: [...basket]
                })
            }}
            max={5}
        />
    ) : (
        <TouchableOpacity
            activeOpacity={0.8}
            style={styles.add}
            onPress={() => {
                let basket: DishType[] = [];
                if (typeof storage.basket !== "undefined" && storage.basket) {
                    basket = storage.basket;
                }
                typeof storage !== "undefined" && setStorage({
                    ...storage,
                    basket: [...basket, dish]
                })
            }}
        >
            <Text style={styles.addText}> Add</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    heroBtn: {
        borderRadius: 10,
        backgroundColor: "white",
        width: "48%",
        flexShrink: 0,
        rowGap: 5,
        columnGap: 5,
        marginBottom: 20,
    },
    add: {
        backgroundColor: "#f1f1f1",
        padding: 8,
        width: "100%",
        borderRadius: 10,
        marginTop: 10,
    },
    addText: {
        color: "black",
        textAlign: "center",
        fontWeight: "bold",
    }
});