import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { HistoryContext } from "../../contexts/HistoryContext";
import Icon from "../Icon";
import { StorageContext } from "../../contexts/StorageContext";
import { useInfiniteQuery, useMutation } from "react-query";
import { API } from "../../utils/api";
import { WalletTransactionType } from "../../types/usertypes";
import moment from "moment";
import { NotificationTypeEnum } from "../../types/notiftypes";
import { PageHeader } from "../navbar/Header";

export default function Notifications() {

    const [history, setHistory] = useContext(HistoryContext);
    const [storage, setStorage] = useContext(StorageContext);
    const [scrolled, setScrolled] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const fetchNotifications = async ({ pageParam = 0 }) => {
        return await API.notifications.list(pageParam);
    };

    const notifQuery = useInfiniteQuery("notifications", fetchNotifications, {
        onSuccess: (data) => {
            const unopened = data.pages[0].unopened_count || 0;
            setStorage({ ...storage, notifications: unopened });
        },
        refetchInterval: 10000,
        getNextPageParam: (lastPage) => lastPage.has_next ? lastPage.offset + 10 : undefined
    });

    const loadMore = () => {
        if (notifQuery.hasNextPage) notifQuery.fetchNextPage();
    }

    const notifRead = useMutation(async () => await API.notifications.see());

    const onRefresh = useCallback(async () => {
        setRefreshing(true);
        await notifQuery.refetch();
        setRefreshing(false);
    }, []);

    useEffect(() => {
        if (history[history.length - 1].page === "NOTIFICATIONS") notifRead.mutate();
    }, [history]);

    const contents = [
        {
            type: NotificationTypeEnum.WALLET_CREDIT,
            title: (transaction: WalletTransactionType) => `Rs. ${transaction.amount} credited to wallet`,
            icon: "wallet"
        },
        {
            type: NotificationTypeEnum.WALLET_DEBIT,
            title: (transaction: WalletTransactionType) => `Rs. ${transaction.amount} debited from wallet`,
            icon: "wallet"
        },
        {
            type: NotificationTypeEnum.ORDER_PLACED,
            title: (order: any) => `Your order has been placed`,
            icon: "fast-food"
        },
        {
            type: NotificationTypeEnum.ORDER_REJECTED,
            title: (order: any) => `Your order has been rejected by the vendor. Money will be refunded to your wallet`,
            icon: "alert-circle"
        },
        {
            type: NotificationTypeEnum.ORDER_CANCELLED,
            title: (order: any) => `Your order has been cancelled by you. Money will be refunded to your wallet`,
            icon: "alert-circle"
        },
        {
            type: NotificationTypeEnum.ORDER_DELIVERED,
            title: (order: any) => `Your order has been delivered. Enjoy your meal!`,
            icon: "checkmark-circle"
        },
        {
            type: NotificationTypeEnum.ORDER_READY,
            title: (order: any) => `Your order is ready! Please pick it up from the counter.`,
            icon: "fast-food"
        }
    ]

    const renderItem = ({ item, index }) => {
        const notif = item;
        const lastNotif = (notifQuery.data?.pages.map(page => page.results).flat() || [])[index - 1];
        const showDate = typeof lastNotif === "undefined" || !lastNotif || moment(notif.created_at).format("DD MMM YYYY") !== moment(lastNotif.created_at).format("DD MMM YYYY");
        const cont = contents.find(c => c.type === notif.type);
        return (
            <React.Fragment key={index}>
                {showDate && (
                    <View
                        style={{
                            marginTop: 30
                        }}
                    >
                        <Text style={{ color: "gray", fontFamily: "Manrope", fontSize: 12 }}>
                            {moment(notif.created_at).format("DD MMM YYYY")}
                        </Text>
                    </View>
                )}
                <TouchableOpacity
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 10,
                        marginTop: 20,
                    }}
                >
                    <View style={{
                        marginRight: 10,
                        width: 50,
                        height: 50,
                        backgroundColor: "#e1e1e1",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 50
                    }}>
                        <Icon name={(cont?.icon || "fast-food") + "-outline"} style={[{ fontSize: 30 }]} />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={[txtStyles.base, { color: "gray", fontSize: 12 }]}>
                            {moment(notif.created_at).format("hh:mm A")}
                        </Text>
                        <Text style={[txtStyles.base, { fontFamily: "manropebold", color: notif.read ? "gray" : "black" }]}>
                            {cont?.title(notif.content.transaction)}
                        </Text>
                    </View>
                    <View style={{ padding: 10 }}>
                        <View
                            style={{
                                backgroundColor: notif.opened ? "transparent" : "orange",
                                width: 8,
                                height: 8,
                                borderRadius: 50,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </React.Fragment>
        )
    }

    return (
        <>
            <PageHeader
                scrolled={scrolled}
                title={`Notfications`}
            />
            <FlatList
                contentContainerStyle={[pageStyles.page, { paddingBottom: 100 }]}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                onScroll={(e) => {
                    const scrolled = e.nativeEvent.contentOffset.y;
                    setScrolled(scrolled);
                }}
                removeClippedSubviews={true}
                style={[{ position: "relative", paddingTop: 40, marginTop: 0 }]}
                data={notifQuery.data?.pages.map(page => page.results).flat() || []}
                onEndReachedThreshold={0.7}
                onEndReached={loadMore}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderItem}
            />
        </>
    )
}