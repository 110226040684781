import { StyleProp, Text, TouchableOpacity, View, ViewStyle } from "react-native";

export default function QuantityInput(props: { onAdd: () => void, onRemove: () => void, quantity: number, max?: number, style?: StyleProp<ViewStyle> }) {
    const { onAdd, onRemove, quantity, max, style } = props;
    const onAddClick = () => {
        if (!max || quantity < max) {
            onAdd();
        }
    }
    return (
        <View
            style={[{
                padding: 8,
                width: "100%",
                borderRadius: 10,
                backgroundColor: "orange",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                paddingHorizontal: 15
            }, style]}
        >
            <TouchableOpacity
                activeOpacity={0.8}
                onPress={onRemove}
                style={{ width: 40, position: "absolute", left: 0, top: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Text style={{ color: "white" }}>-</Text>
            </TouchableOpacity>
            <View>
                <Text style={[{ textAlign: "center", fontWeight: "bold", color: "white" }]}>{quantity}</Text>
            </View>

            <TouchableOpacity
                activeOpacity={0.8}
                onPress={onAddClick}
                style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Text style={{ color: "white" }}>+</Text>
            </TouchableOpacity>
        </View>
    )
}