import LottieView from "lottie-react-native";
import { useContext, useEffect, useRef, useState } from "react";
import { View, StyleSheet, ActivityIndicator, Text, Platform, StatusBar } from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { HistoryContext } from "../contexts/HistoryContext";
import { StorageContext } from "../contexts/StorageContext";
import { txtStyles } from "../styles/Text";
import { EntityType, OrderType } from "../types/ordertypes";
import { API } from "../utils/api";
import Loader from "./Loading";
import { WebView } from 'react-native-webview';

export default function ConfirmationPage() {
    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);

    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [checkStarted, setCheckStarted] = useState<number | null>(null);
    const [transactionFailed, setTransactionFailed] = useState(false);
    const [sessionId, setSessionId] = useState<string | null>(null);

    const page = history[history.length - 1];
    const entityType: EntityType = page?.meta?.entityType || EntityType.ORDER;
    const isUpi = typeof page?.meta?.paymentMethod !== "undefined" && page?.meta?.paymentMethod === "UPI";
    const upi_id = page?.meta?.upi_id || "";
    const queryClient = useQueryClient();
    const basket = typeof storage?.basket !== "undefined" ? storage.basket : [];

    const orderMutation = useMutation(async (order: { dishes: string[], transaction_type: number }) => await API.orders.create(order), {
        onSuccess: async (data) => {
            await queryClient.refetchQueries("me");
            await queryClient.refetchQueries("notifications");
            await queryClient.refetchQueries("wallet");
            await queryClient.refetchQueries("undelivered_orders");
            if (!isUpi) {
                setLoading(false);
                setStorage({
                    ...storage,
                    basket: [],
                });
            }
        },
        onError: async (error) => {
            setLoading(false);
        }
    });
    const walletTransaction = useMutation(async (params: { amount: number }) => await API.wallet.transaction.create(params.amount), {
        onSuccess: async (data) => {
            const tdata = await transactionMutation.mutateAsync({
                external_id: data.external_id,
                entity_type: EntityType.WALLET,
            });
            if (Platform.OS === "web") {
                window.open('https://www.bavrchi.com/payment?session_id=' + tdata?.payment_session_id + "&order_id=" + tdata?.order_id + "&amount=" + tdata?.amount, "_blank")
            }
        },
        onError: async (error) => {
            setLoading(false);
        }
    });
    const transactionMutation = useMutation((params: { external_id: string, entity_type: EntityType }) => API.transactions.create(params.external_id, params.entity_type), {
        onSuccess: async (data) => {
            setSessionId(data.payment_session_id);
        },
        onError: async (error) => {
            setLoading(false);
        }
    });

    useEffect(() => {
        if (entityType === EntityType.ORDER) {
            handleOrder(isUpi);
        } else if (entityType === EntityType.WALLET) {
            const amount = page?.meta?.amount;
            handleWallet(amount, page?.meta?.upi_id);
        }
        return () => {
            console.log("unmounting")
        }
    }, [])

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setHistory([...history, {
                    page: entityType === EntityType.ORDER ? "ORDERS" : "WALLET",
                }]);
                if (entityType === EntityType.ORDER) {
                    setStorage({
                        ...storage,
                        basket: [],
                    });
                }
            }, 3000)
            if (!!upi_id && !!storage.saveUpiId) {
                setStorage({
                    ...storage,
                    upiId: upi_id,
                });
            }
        }
    }, [loading])

    useEffect(() => {
        const tId = transactionMutation.data?.external_id || transactionId;
        if (tId) {
            if (!checkStarted) {
                setCheckStarted(Date.now());
            }
            if (checkStarted && Date.now() - checkStarted > 600000) {
                setLoading(false);
                setTransactionFailed(true);
            } else {
                const interval = setInterval(async () => {
                    const transaction = await API.transactions.get(tId);
                    if (transaction.status === 2) {
                        setLoading(false);
                        await queryClient.refetchQueries("undelivered_orders");
                        setStorage({
                            ...storage,
                            basket: [],
                        });
                    }
                }, 5000);
                return () => clearInterval(interval);
            }

        }
    }, [transactionId, transactionMutation.data?.external_id])

    const handleOrder = async (isUpi?: boolean) => {
        const order: OrderType = await orderMutation.mutateAsync({
            dishes: basket.map((b) => b.external_id),
            transaction_type: isUpi ? 1 : 2,
        });
        if (isUpi) {
            const external_id = order.external_id;
            const data = await transactionMutation.mutateAsync({
                external_id,
                entity_type: EntityType.ORDER,
            });
            if (Platform.OS === "web") {
                console.log(data)
                window.open('https://www.bavrchi.com/payment?session_id=' + data?.payment_session_id + "&order_id=" + data?.order_id + "&amount=" + data?.amount, "_blank")
            }
        }
    }

    const handleWallet = async (amount: number, upi_id: string) => {
        await walletTransaction.mutateAsync({
            amount,
        });
        await queryClient.refetchQueries("wallet");
    }

    const onMessage = (event: any) => {
        const order_id = event.nativeEvent.data;
        setSessionId(null);
        setTransactionId(order_id);
    }

    return (
        <View style={[styles.container]}>
            {sessionId && Platform.OS !== "web" &&
                <View style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    backgroundColor: "white",
                    paddingTop: StatusBar.currentHeight
                }}>
                    <WebView
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                        mixedContentMode="compatibility"
                        onMessage={onMessage}
                        source={{ uri: 'https://www.bavrchi.com/payment?session_id=' + sessionId + "&order_id=" + transactionMutation.data?.order_id + "&amount=" + transactionMutation.data?.amount }}
                    />
                </View>
            }
            {loading ? <>
                <Loader />
                {isUpi && transactionMutation.isSuccess && <View style={{
                    transform: [{ translateY: Platform.OS === "web" ? 0 : -120 }],
                }}>
                    <Text style={[txtStyles.largeText, { textAlign: "center" }]}>
                        Awaiting Confirmation
                    </Text>
                    <Text style={[txtStyles.base, { textAlign: "center", width: 250 }]}>
                        Waiting for payment confirmation
                    </Text>
                </View>
                }
            </> : (transactionMutation.isError || orderMutation.isError || transactionFailed ?
                <>
                    {Platform.OS === "web" || <LottieView
                        style={[{
                            width: 200,
                            height: 200,
                        }]}
                        source={require('../animations/error.json')}
                        autoPlay
                        loop={false}
                        onAnimationFinish={() => setTimeout(() => {
                            setHistory([...history, {
                                page: "ORDERS",
                            }]);
                        }, 500)}
                    />}
                    <View style={{
                        transform: [{ translateY: 0 }],
                    }}>
                        <Text style={[txtStyles.largeText, { textAlign: "center" }]}>
                            An error occured :(
                        </Text>
                        <Text style={[txtStyles.base, { textAlign: "center", width: 250 }]}>
                            Please try again later
                        </Text>
                    </View>
                </> :
                <>
                    {Platform.OS === "web" ||
                        <LottieView
                            style={[{
                                width: 400,
                                height: 400,
                            }]}
                            source={require('../animations/check.json')}
                            autoPlay
                            loop={false}
                            onAnimationFinish={() => setTimeout(() => {
                                setHistory([...history, {
                                    page: "ORDERS",
                                }]);
                            }, 500)}
                        />
                    }
                    <View style={{
                        transform: [{ translateY: Platform.OS === "web" ? 0 : -70 }],
                    }}>
                        <Text style={[txtStyles.largeText, { textAlign: "center" }]}>
                            {entityType === EntityType.ORDER ? "Order Confirmed" : "Recharge Successful"}
                        </Text>
                        <Text style={[txtStyles.base, { textAlign: "center", width: 250 }]}>
                            {entityType === EntityType.ORDER && "We will let you know when your order is ready"}
                        </Text>
                    </View>

                </>
            )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
})
