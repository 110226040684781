import { useCallback, useContext, useState } from "react";
import { ScrollView, StyleSheet, Switch, Text, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { HistoryContext } from "../../contexts/HistoryContext";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import { StorageContext } from "../../contexts/StorageContext";
import Header from "../navbar/Header";
import { Ionicons } from "@expo/vector-icons";
import { API } from "../../utils/api";
import { useInfiniteQuery } from "react-query";
import { OrderType } from "../../types/ordertypes";
import { OrderView } from "./Orders";

export default function Account() {

    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);
    const [refreshing, setRefreshing] = useState(false);

    const logout = () => {
        setStorage({ ...storage, user: undefined, auth_token: undefined, basket: [] });
        setHistory([{ page: "WELCOME" }]);
    }

    const fetchOrders = async ({ pageParam = 0 }) => {
        return await API.orders.list(pageParam);
    };

    const orderQuery = useInfiniteQuery("orders", fetchOrders, {
        onSuccess: (data) => {

        },
        //refetchInterval: 10000,
        getNextPageParam: (lastPage) => lastPage.has_next ? lastPage.offset + 10 : undefined
    });

    const loadMore = () => {
        if (orderQuery.hasNextPage) orderQuery.fetchNextPage();
    }

    const onRefresh = useCallback(async () => {
        setRefreshing(true);
        await orderQuery.refetch();
        setRefreshing(false);
    }, []);

    const data = orderQuery.data?.pages.map(page => page.results).flat() || [];

    return (
        <View style={[pageStyles.page, { padding: 0, flex: 1 }]}>
            <Header
                title="My Account"
                leftIcon="arrow-back-outline"
                leftIconPress={() => setHistory(history.slice(0, history.length - 1))}
                rightIcon="exit-outline"
                rightIconPress={logout}
            />
            <ScrollView
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                contentContainerStyle={{ padding: 15, display: "flex", alignItems: "center" }}
                onScroll={(e) => {
                    let paddingToBottom = 30;
                    paddingToBottom += e.nativeEvent.layoutMeasurement.height;
                    if (e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom) {
                        loadMore();
                    }
                }}
            >
                <Ionicons name="person-circle-outline" style={{ fontSize: 150, color: "gray" }} />
                <Text style={txtStyles.title}>{storage?.user?.full_name}</Text>

                <Text style={txtStyles.base}>
                    @{storage?.user?.username}
                </Text>
                <View style={{ marginTop: 40, width: "100%" }}>
                    <Text style={[txtStyles.largeText, { textAlign: "center" }]}>
                        Order History
                    </Text>
                    <OrderView
                        orders={data}
                    />
                </View>
            </ScrollView>
        </View>

    )
}
