import { Button, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React, { useContext } from "react";
import { PageProps } from "../../types/pagetypes";
import { HistoryContext } from "../../contexts/HistoryContext";
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
export default function NavBarButton(props: {
    name: string,
    link: string,
    icon: string,
    badge?: number,
}) {
    const [history, setHistory] = useContext(HistoryContext);
    const { name, link, icon, badge } = props;
    const page = history[history.length - 1];

    return (
        <TouchableOpacity
            style={styles.button}
            onPress={() => {
                setHistory([...history, { page: link }]);
            }}
        >
            <View style={{ position: "relative" }}>
                {typeof badge !== "undefined" && badge != 0 && <View style={styles.badge}>
                    <Text style={styles.badgeText}>
                        {badge}
                    </Text>
                </View>}
                <Text
                    style={[
                        styles.buttonIcon,
                        {
                            color: page.page === link ? "orange" : "black"
                        }
                    ]}
                >

                    <Ionicons name={(icon + (page.page === link ? "" : "-outline")) as any} style={{ fontSize: 20 }} />
                </Text>
            </View>
            <Text
                style={{
                    ...styles.buttonText,
                    height: page.page === link ? "auto" : 0,
                }}
            >
                {name}
            </Text>

        </TouchableOpacity>
    )
}
const styles = StyleSheet.create({
    button: {
        color: "black",
        padding: 10,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

    },
    buttonIcon: {
        color: "black",
        textAlign: "center",
        fontSize: 20,

    },
    buttonText: {
        fontSize: 11,
        color: "orange",
        marginTop: 0,
        height: 0,
        fontFamily: "Manrope",
        overflow: "hidden",
    },
    badge: {
        position: "absolute",
        top: -10,
        right: -10,
        backgroundColor: "orange",
        borderRadius: 10,
        width: 16,
        height: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    badgeText: {
        color: "white",
        fontSize: 8,
    }
});