import { createContext, ReactNode } from "react";
import { UserType } from "../types/usertypes";
import { DishType } from "../types/vendors";

export type StorageType = {
    user?: UserType;
    auth_token?: string;
    basket?: DishType[];
    notifications?: number;
    modal?: ReactNode;
    upiId?: string;
    saveUpiId?: boolean;
}

export type StorageHookType = [StorageType | null, (store: StorageType) => void];

export const StorageContext = createContext<StorageHookType>([null, () => { }]);