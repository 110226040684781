import { useContext, useState } from "react";
import { Button, Dimensions, Image, Linking, Platform, StatusBar, Text, TextInput, TouchableOpacity, View } from "react-native";
import { useMutation } from "react-query";
import { HistoryContext } from "../contexts/HistoryContext";
import { StorageContext } from "../contexts/StorageContext";
import { pageStyles } from "../styles/Page";
import { txtStyles } from "../styles/Text";
import { API } from "../utils/api";
import LottieView from "lottie-react-native";
import { Ionicons } from '@expo/vector-icons';

export default function LoginScreen() {
    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);
    const loginQuery = useMutation((credentials: { email: string, password: string }) => API.user.login(credentials.email, credentials.password), {
        onSuccess: (data) => {
            const token: string = data.token;
            setStorage({ ...storage, auth_token: token });
        },
        onError: (error: any) => {
            setErrors(error.error);
        }
    });
    const [credentials, setCredentials] = useState<{ email: string, password: string }>({ email: "", password: "" });
    const [errors, setErrors] = useState<any>(null);

    const meta = history[history.length - 1].meta;

    const handleSubmit = () => {
        setErrors(null);
        let valid = true;
        if (credentials.email === "") {
            setErrors({ ...errors, email: "Email is required" });
            valid = false;
        }
        if (credentials.password === "") {
            setErrors({ ...errors, password: "Password is required" });
            valid = false;
        }
        if (valid) loginQuery.mutate(credentials);
    }

    return (
        <View style={[pageStyles.page, { justifyContent: "space-between", flex: 1 }]}>
            <View>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 20
                }}>
                    <TouchableOpacity
                        onPress={() => {
                            setHistory(history.slice(0, history.length - 1))
                        }}
                    >
                        <Ionicons name={'arrow-back-outline'} style={{ fontSize: 30, marginRight: 10 }} />
                    </TouchableOpacity>
                    <Text style={[txtStyles.title,]}>
                        Login
                    </Text>
                </View>
                <TextInput
                    style={pageStyles.input}
                    placeholderTextColor="gray"
                    placeholder="Email Id"
                    onChangeText={newText => setCredentials({ ...credentials, email: newText })}
                    defaultValue={credentials.email}
                />
                {errors?.email && <Text style={{ color: "red" }}>{errors.email}</Text>}
                <TextInput
                    style={[pageStyles.input, { marginTop: 10 }]}
                    placeholderTextColor="gray"
                    secureTextEntry={true}
                    placeholder="Password"
                    onChangeText={newText => setCredentials({ ...credentials, password: newText })}
                    defaultValue={credentials.password}
                />
                {errors?.password && <Text style={{ color: "red" }}>{errors.password}</Text>}
                {errors?.non_field_errors && <Text style={{ color: "red" }}>{errors.non_field_errors}</Text>}
                <Text
                    style={{ color: "orange", marginTop: 20 }}
                    onPress={() => setHistory([...history, { page: "FORGOT" }])}
                >
                    Forgot Password?
                </Text>
                {meta?.message &&
                    <View style={{
                        backgroundColor: meta.type === "error" ? "red" : "rgba(0, 255, 0, 0.2)",
                        padding: 10,
                        borderRadius: 10,
                        marginTop: 20,
                        borderColor: meta.type === "error" ? "red" : "green",
                        borderWidth: 1
                    }}>
                        <Text style={[txtStyles.base, { color: meta.type === "error" ? "red" : "green" }]}>
                            {meta.message}
                        </Text>
                    </View>}
            </View>
            <View>
                <TouchableOpacity
                    style={[pageStyles.button, { marginTop: 20 }]}
                    onPress={handleSubmit}
                >
                    <Text style={pageStyles.buttonText}>
                        Login
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export function RegisterScreen() {
    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);
    const registerQuery = useMutation((creds: typeof credentials) => API.user.register(creds), {
        onSuccess: (data) => {
            const token: string = data.token;
            setStorage({ ...storage, auth_token: token });
        },
        onError: (error: any) => {
            setErrors(error.error);
        }
    });
    const [credentials, setCredentials] = useState<{ email: string, password: string, username: string, full_name: string }>({ email: "", password: "", username: "", full_name: "" });
    const [errors, setErrors] = useState<any>(null);

    const handleSubmit = () => {
        setErrors(null);
        let valid = true;
        let errors: any = {};
        if (credentials.email === "") {
            errors.email = "Email is required";
        }
        if (credentials.username === "") {
            errors.username = "Username is required";
        }
        if (credentials.full_name === "") {
            errors.full_name = "Full Name is required";
        }
        if (credentials.password === "") {
            errors.password = "Password is required";
        }
        if (errors.email || errors.username || errors.full_name || errors.password) {
            setErrors(errors);
            valid = false;
        }
        if (valid) registerQuery.mutate(credentials);
    }
    return (
        <View style={[pageStyles.page, { justifyContent: "space-between", flex: 1 }]}>
            <View>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 20
                }}>
                    <TouchableOpacity
                        onPress={() => {
                            setHistory(history.slice(0, history.length - 1))
                        }}
                    >
                        <Ionicons name={'arrow-back-outline'} style={{ fontSize: 30, marginRight: 10 }} />
                    </TouchableOpacity>
                    <Text style={[txtStyles.title,]}>
                        Register
                    </Text>
                </View>

                <TextInput
                    style={pageStyles.input}
                    placeholderTextColor="gray"
                    placeholder="Email Id"
                    onChangeText={newText => setCredentials({ ...credentials, email: newText })}
                    defaultValue={credentials.email}
                />
                {errors?.email && <Text style={{ color: "red" }}>{errors.email}</Text>}
                <TextInput
                    style={[pageStyles.input, { marginTop: 10 }]}
                    placeholderTextColor="gray"
                    placeholder="Username"
                    onChangeText={newText => setCredentials({ ...credentials, username: newText })}
                    defaultValue={credentials.username}
                />
                {errors?.username && <Text style={{ color: "red" }}>{errors.username}</Text>}
                <TextInput
                    style={[pageStyles.input, { marginTop: 10 }]}
                    placeholderTextColor="gray"
                    placeholder="Full Name"
                    onChangeText={newText => setCredentials({ ...credentials, full_name: newText })}
                    defaultValue={credentials.full_name}
                />
                {errors?.full_name && <Text style={{ color: "red" }}>{errors.full_name}</Text>}
                <TextInput
                    style={[pageStyles.input, { marginTop: 10 }]}
                    placeholderTextColor="gray"
                    secureTextEntry={true}
                    placeholder="Password"
                    onChangeText={newText => setCredentials({ ...credentials, password: newText })}
                    defaultValue={credentials.password}
                />
                {errors?.password && <Text style={{ color: "red" }}>{errors.password}</Text>}
                {errors?.non_field_errors && <Text style={{ color: "red" }}>{errors.non_field_errors}</Text>}
                <Text style={[txtStyles.base, { marginTop: 20 }]}>
                    By registering, you agree to our{" "}
                    <Text
                        style={{ color: "orange" }}
                        onPress={() => Linking.openURL('https://www.bavrchi.com/docs/terms-and-conditions')}
                    >
                        Terms and Conditions
                    </Text>
                    {" "}
                    and
                    {" "}
                    <Text
                        style={{ color: "orange" }}
                        onPress={() => Linking.openURL('https://www.bavrchi.com/docs/privacy-policy')}
                    >
                        Privacy Policy
                    </Text>.
                </Text>
            </View>
            <View>
                <TouchableOpacity
                    style={[pageStyles.button, { marginTop: 20 }]}
                    onPress={handleSubmit}
                >
                    <Text style={pageStyles.buttonText}>
                        Register
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export function WelcomeScreen() {

    const [history, setHistory] = useContext(HistoryContext);

    return (
        <View style={[pageStyles.page, { justifyContent: "space-between", flex: 1, alignItems: "center" }]}>
            <Image
                source={require('../../assets/comic.png')}
                style={{ position: "absolute", left: 0, top: -(Platform.OS === "web" ? 0 : StatusBar.currentHeight), right: 0, width: Dimensions.get("screen").width, height: 300, resizeMode: "cover" }}
            />
            <Image
                source={require('../../assets/bavrchi-mono.png')}
                style={{ position: "absolute", top: 20, width: 200, height: 100, resizeMode: "contain", zIndex: 10 }}
            />
            <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                {Platform.OS === "web" ||
                    <LottieView
                        style={[{
                            width: 300,
                            height: 300,
                        }]}
                        source={require('../animations/open-screen.json')}
                        autoPlay
                        loop={true}
                    />
                }
                <Text style={[txtStyles.largeText, { textAlign: "center", width: 250, marginBottom: 15 }]}>
                    Good food, now at your fingertips
                </Text>
                <Text style={[txtStyles.base, { width: 250, textAlign: "center" }]}>
                    Sit tight, and let bavrchi take care of the rest
                </Text>
            </View>
            <View style={{ width: "100%" }}>
                <TouchableOpacity
                    style={[pageStyles.button]}
                    onPress={() => setHistory([...history, { page: "REGISTER" }])}
                >
                    <Text style={[pageStyles.buttonText]}>
                        Register
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[pageStyles.button, { backgroundColor: "transparent" }]}
                    onPress={() => setHistory([...history, { page: "LOGIN" }])}
                >
                    <Text style={[pageStyles.buttonText, { color: "orange" }]}>
                        Login
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export function ForgotPassword() {
    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);
    const [stage, setStage] = useState<"EMAIL" | "OTP" | "PASSWORD">("EMAIL");
    const [credentials, setCredentials] = useState<{ email: string, otp: string, password: string }>({ email: "", otp: "", password: "" });
    const [errors, setErrors] = useState<any>(null);

    const forgotQuery = useMutation((credentials: { email: string }) => API.forgotPassword.initiate(credentials.email), {
        onSuccess: (data) => {
            setStage("OTP");
        },
        onError: (error: any) => {
            setErrors(error.error);
        }
    });

    const verifyQuery = useMutation((credentials: { email: string, otp: string }) => API.forgotPassword.verify(credentials.email, credentials.otp), {
        onSuccess: (data) => {
            setStage("PASSWORD");
        },
        onError: (error: any) => {
            setErrors(error.error);
        }
    });

    const resetQuery = useMutation((credentials: { email: string, otp: string, password: string }) => API.forgotPassword.reset(credentials.email, credentials.otp, credentials.password), {
        onSuccess: (data) => {
            setHistory(history.slice(0, history.length - 3).concat({
                page: "LOGIN",
                meta: {
                    message: "Password reset successfully"
                }
            }));
        },
        onError: (error: any) => {
            setErrors(error.error);
        }
    });

    const handleSubmit = () => {
        switch (stage) {
            case "EMAIL": {
                setErrors(null);
                let valid = true;
                if (credentials.email === "") {
                    setErrors({ ...errors, message: "Email is required" });
                    valid = false;
                }
                if (valid) forgotQuery.mutate(credentials);
                break;
            }
            case "OTP": {
                setErrors(null);
                let valid = true;
                if (credentials.otp === "") {
                    setErrors({ ...errors, message: "OTP is required" });
                    valid = false;
                }
                if (valid) verifyQuery.mutate(credentials);
                break;
            }
            case "PASSWORD": {
                setErrors(null);
                let valid = true;
                if (credentials.password === "") {
                    setErrors({ ...errors, message: "Password is required" });
                    valid = false;
                }
                if (valid) resetQuery.mutate(credentials);
                break;
            }
        }
    }

    return (
        <View style={[pageStyles.page, { justifyContent: "space-between", flex: 1 }]}>
            <View>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 20
                }}>
                    <TouchableOpacity
                        onPress={() => {
                            setHistory(history.slice(0, history.length - 1))
                        }}
                    >
                        <Ionicons name={'arrow-back-outline'} style={{ fontSize: 30, marginRight: 10 }} />
                    </TouchableOpacity>
                    <Text style={[txtStyles.title,]}>
                        {stage === "EMAIL" ? "Forgot Password" : stage === "OTP" ? "Verify OTP" : "Reset Password"}
                    </Text>
                </View>
                {stage === "EMAIL" &&
                    <TextInput
                        style={pageStyles.input}
                        placeholderTextColor="gray"
                        placeholder="Email Id"
                        onChangeText={newText => setCredentials({ ...credentials, email: newText })}
                        defaultValue={credentials.email}
                    />}
                {stage === "OTP" &&
                    <TextInput
                        style={pageStyles.input}
                        placeholderTextColor="gray"
                        placeholder="OTP"
                        onChangeText={newText => setCredentials({ ...credentials, otp: newText })}
                        defaultValue={credentials.otp}
                    />}
                {stage === "PASSWORD" &&
                    <TextInput
                        style={pageStyles.input}
                        placeholderTextColor="gray"
                        placeholder="New Password"
                        onChangeText={newText => setCredentials({ ...credentials, password: newText })}
                        defaultValue={credentials.password}
                        secureTextEntry={true}
                    />}
                {errors?.message && <Text style={{ color: "red" }}>{errors.message}</Text>}

                {stage === "EMAIL" &&
                    <Text style={[txtStyles.base, { marginTop: 20, color: "gray", fontSize: 14 }]}>
                        An OTP will be sent to your email id.
                    </Text>}
                {stage === "OTP" &&
                    <Text
                        style={{ color: "orange", marginTop: 20 }}
                        onPress={() => setHistory(history.slice(0, history.length - 1))}
                    >
                        Back to Login
                    </Text>}
                {stage === "PASSWORD" &&
                    <Text
                        style={{ color: "orange", marginTop: 20 }}
                        onPress={() => setStage("EMAIL")}
                    >
                        Go back
                    </Text>}
            </View>
            <View>
                <TouchableOpacity
                    style={[pageStyles.button, { marginTop: 20 }]}
                    onPress={handleSubmit}
                >
                    <Text style={pageStyles.buttonText}>
                        {stage === "EMAIL" ? "Send OTP" : stage === "OTP" ? "Verify OTP" : "Reset Password"}
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
