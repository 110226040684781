import { ActivityIndicator, View, Platform } from "react-native";
import LottieView from "lottie-react-native";

export default function Loader(
    props: {
        style?: any,
    }
) {

    if (Platform.OS === "web")
        return (
            <View>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        )

    return (
        <LottieView
            style={[{
                width: 400,
                height: 400,
            }, props.style]}
            source={require('../animations/loading-food.json')}
            autoSize
            autoPlay
            loop
        />
    )
}

export function Loading() {

    return (
        <View style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Loader />
        </View>
    )
}