import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Platform } from 'react-native';

export const registerForPushNotificationsAsync = async () => {
    let token;
    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            console.log("Requesting permissions");
            try {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
                console.log("Status: ", status);
            } catch (error) {
                console.log("Error requesting permissions: ")
                console.log(error);
            }
        }
        if (finalStatus !== 'granted') {
            console.log('Failed to get push token for push notification!');
            return;
        }
        try {
            token = (await Notifications.getExpoPushTokenAsync()).data;
        } catch (error) {
            console.log("Error getting token: ")
            console.log(error);
        }

    } else {
        console.log('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
        try {
            await Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        } catch (error) {
            console.log('Error setting notification channel:', error);
        }
    }

    return token;
}