import { StatusBar, StyleSheet } from "react-native";

export const pageStyles = StyleSheet.create({
    page: {
        padding: 15,
        marginTop: StatusBar.currentHeight
    },
    column_2: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10
    },
    input: {
        backgroundColor: "#f1f1f1",
        color: "black",
        padding: 15,
        borderRadius: 10,
        fontSize: 16,
        width: "100%",
        fontFamily: "Manrope",
    },
    titleFlex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    button: {
        backgroundColor: "orange",
        padding: 15,
        borderRadius: 10,
    },
    buttonLight: {
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        padding: 15,
        borderRadius: 10,
    },
    buttonText: {
        fontFamily: "Manrope",
        color: "white",
        fontSize: 16,
        textAlign: "center",
    },
    buttonLightText: {
        fontFamily: "Manrope",
        color: "orange",
        fontSize: 16,
        textAlign: "center",
    },
    singleButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 20,
        padding: 0,
        width: 40,
        height: 40,
        borderRadius: 20,
    },
});