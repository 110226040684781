import { useCallback, useContext, useState } from "react";
import { ScrollView, StyleSheet, Switch, Text, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { HistoryContext } from "../../contexts/HistoryContext";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import { StorageContext } from "../../contexts/StorageContext";
import Header from "../navbar/Header";
import { Ionicons } from "@expo/vector-icons";
import { API } from "../../utils/api";
import { useInfiniteQuery } from "react-query";
import { OrderType } from "../../types/ordertypes";
import { OrderView } from "./Orders";
import { Dish } from "./Home";

export default function Addons() {

    const [storage, setStorage] = useContext(StorageContext);
    const [history, setHistory] = useContext(HistoryContext);
    const [refreshing, setRefreshing] = useState(false);

    const currentHistory = history[history.length - 1];

    const fetchAddons = async ({ pageParam = 0 }) => {
        return await API.dishes.list(currentHistory.meta.vendor, { addon: true, offset: pageParam })
    };

    const addonQuery = useInfiniteQuery(["dishes", "addons"], fetchAddons, {
        getNextPageParam: (lastPage) => lastPage.has_next ? lastPage.offset + 10 : undefined
    });

    const onRefresh = useCallback(async () => {
        setRefreshing(true);
        await addonQuery.refetch();
        setRefreshing(false);
    }, []);

    const loadMore = () => {
        if (addonQuery.hasNextPage) addonQuery.fetchNextPage();
    }

    console.log(addonQuery.data);

    return (
        <View style={[pageStyles.page, { padding: 0, flex: 1 }]}>
            <Header
                title="Add Ons"
                leftIcon="arrow-back-outline"
                leftIconPress={() => setHistory(history.slice(0, history.length - 1))}
            />
            <ScrollView
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                contentContainerStyle={{ padding: 15, display: "flex", alignItems: "center" }}
                onScroll={(e) => {
                    let paddingToBottom = 30;
                    paddingToBottom += e.nativeEvent.layoutMeasurement.height;
                    if (e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom) {
                        loadMore();
                    }
                }}
            >
                {addonQuery.isLoading && <Text>Loading...</Text>}
                <View style={{ marginTop: 40, width: "100%", flexDirection: "row" }}>
                    {addonQuery.data?.pages.map(page => page.results).flat().map((addon, i) => (
                        <Dish dish={addon} key={i} isAddon={true} />
                    ))}
                </View>
            </ScrollView>
        </View>

    )
}
