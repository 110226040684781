import { StatusBar } from 'expo-status-bar';
import { useEffect, useRef, useState } from 'react';
import { BackHandler, StyleSheet, View, Dimensions, Platform } from 'react-native';
import AppView from './src/components/AppView';
import { HistoryContext, HistoryType } from './src/contexts/HistoryContext';
import { useFonts } from 'expo-font';
import { StorageContext, StorageType } from './src/contexts/StorageContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as NavigationBar from 'expo-navigation-bar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { API } from './src/utils/api';
import * as Notifications from 'expo-notifications';
import { registerForPushNotificationsAsync } from './src/utils/notificationUtils';
import { ModalSlide } from './src/components/Modal';
import { WELCOME_PAGES } from './src/components/AppView';

export default function App() {
  const [fontsLoaded] = useFonts({
    'Manrope': require('./src/fonts/regular.otf'),
    'manropebold': require('./src/fonts/bold.otf'),
  });

  const [history, setHistory] = useState<HistoryType[]>([{ page: "HOME" }]);
  const [storage, setStorage] = useState<StorageType | null>(null);

  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState<any>(false);
  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  const queryClient = new QueryClient();

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    }),
  });

  const backAction = () => {
    setHistory((h) => {
      if (h.length > 1) {
        return h.slice(0, h.length - 1);
      } else {
        console.log("Exiting app");
        BackHandler.exitApp();
        return h;
      }
    });
    return true;
  }

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backAction);
    getStorage();
    NavigationBar.setBackgroundColorAsync('#fff');

    registerForPushNotificationsAsync().then(token => setExpoPushToken(token));

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response);
    });

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', backAction);
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    }
  }, [])

  useEffect(() => {
    if (expoPushToken !== "" && storage?.auth_token) {
      API.notifications.createPushToken(expoPushToken);
    }
  }, [expoPushToken])

  const getStorage = async () => {
    const storage = await AsyncStorage.getItem("storage");
    if (storage) {
      setStorage(JSON.parse(storage));
    } else {
      setStorage({});
    }
  }

  const loadUser = async () => {
    try {
      const userData = await API.user.me();
      if (userData) {
        setStorage({
          ...storage,
          user: userData,
        })
        if (WELCOME_PAGES.includes(history[history.length - 1].page)) {
          setHistory([{ page: "HOME" }]);
        }
      } else {
        throw new Error("User data not found");
      }
    } catch (e) {
      console.log(e);
      setStorage({
        ...storage,
        auth_token: null,
      })
      setHistory([{ page: "WELCOME" }]);
    }
  }

  useEffect(() => {
    if (storage) {
      const volatiles: (keyof StorageType)[] = ["modal"];
      const newStorage = { ...storage };
      volatiles.forEach((v) => {
        delete newStorage[v];
      })
      AsyncStorage.setItem("storage", JSON.stringify(newStorage));
    }
  }, [storage])

  useEffect(() => {

    if (storage?.auth_token) {
      setHistory([{ page: "LOADING" }]);
      loadUser();
    } else {
      setHistory([{ page: "WELCOME" }]);
    }
  }, [storage?.auth_token])

  return (
    <View style={styles.main}>
      {fontsLoaded && (
        <>
          <StatusBar style="dark" />
          <QueryClientProvider client={queryClient}>
            <StorageContext.Provider value={[storage, setStorage]}>
              <HistoryContext.Provider value={[history, setHistory]}>
                <AppView />
                <ModalSlide />
              </HistoryContext.Provider>
            </StorageContext.Provider>
          </QueryClientProvider>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: '#fff',
    height: Platform.OS === "web" ? Dimensions.get('window').height : "100%",
    overflow: "hidden",
  }
});
