import { ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { pageStyles } from "../../styles/Page";
import { txtStyles } from "../../styles/Text";
import { useCallback, useContext, useEffect, useState } from "react";
import { HistoryContext } from "../../contexts/HistoryContext";
import Icon from "../Icon";
import { StorageContext } from "../../contexts/StorageContext";
import { useInfiniteQuery, useQuery } from "react-query";
import { API } from "../../utils/api";
import { WalletTransactionEnum, WalletTransactionType } from "../../types/usertypes";
import { Ionicons } from '@expo/vector-icons';
import moment from "moment";
import { EntityType } from "../../types/ordertypes";
import UpiInput from "../UpiInput";
import QRCode from "react-native-qrcode-svg";

export default function Wallet() {
    const [storage, setStorage] = useContext(StorageContext);
    const [refreshing, setRefreshing] = useState(false);

    const meQuery = useQuery("me", async () => API.user.me(), {
        onSuccess: (data) => {
            setStorage({ ...storage, user: data });
        },
        onError: (error) => {
            console.log("me error", error);
        },
        //refetchInterval: 10000,
    });

    const fetchWallet = async ({ pageParam = 0 }) => {
        return await API.wallet.transaction.list(pageParam);
    };

    const walletQuery = useInfiniteQuery("wallet", fetchWallet, {
        onSuccess: (data) => {

        },
        //refetchInterval: 10000,
        getNextPageParam: (lastPage) => lastPage.has_next ? lastPage.offset + 10 : undefined
    });


    const onRefresh = useCallback(async () => {
        setRefreshing(true);
        await meQuery.refetch();
        await walletQuery.refetch();
        setRefreshing(false);
    }, []);

    const showRechargeModal = () => {
        setStorage({
            ...storage, modal: <WalletRecharge />
        });
    }

    const showCashRechargeModal = () => {
        setStorage({
            ...storage, modal: <WalletRechargeCash />
        });
    }

    const loadMore = () => {
        if (walletQuery.hasNextPage) walletQuery.fetchNextPage();
    }

    const data = walletQuery.data?.pages.map(page => page.results).flat() || [];

    return (
        <ScrollView
            contentContainerStyle={[pageStyles.page, { display: "flex", alignItems: "center", paddingBottom: 50 }]}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            onScroll={(e) => {
                let paddingToBottom = 30;
                paddingToBottom += e.nativeEvent.layoutMeasurement.height;
                if (e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom) {
                    loadMore();
                }
            }}
        >
            <View style={pageStyles.titleFlex}>
                <Text style={txtStyles.title}>Wallet</Text>
            </View>
            <View>
                <Text style={[txtStyles.title, { fontSize: 50 }]}>
                    &#8377; {storage?.user?.wallet_balance}
                </Text>
            </View>
            <View style={{ marginTop: 20 }}>
                <TouchableOpacity
                    style={[pageStyles.button, { padding: 10, paddingHorizontal: 20 }]}
                    onPress={showRechargeModal}
                >
                    <Text style={pageStyles.buttonText}>Recharge</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[pageStyles.buttonLight, { padding: 10, paddingHorizontal: 20, marginTop: 10 }]}
                    onPress={showCashRechargeModal}
                >
                    <Text style={pageStyles.buttonLightText}>Cash Recharge</Text>
                </TouchableOpacity>
            </View>
            <View style={{ marginTop: 80, width: "100%" }}>
                <Text style={txtStyles.largeText}>Transactions</Text>
                <View
                    style={{
                        display: "flex",
                        marginTop: 20,
                    }}
                >
                    {walletQuery.isLoading ? (
                        <Text>Loading...</Text>
                    ) : (
                        data.map((transaction: WalletTransactionType, index) => (
                            <View key={"wt_" + index} style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 10, borderColor: "#eee", borderWidth: 1, borderRadius: 15, marginBottom: 10 }}>
                                <View
                                    style={{
                                        backgroundColor: transaction.type === WalletTransactionEnum.CREDIT ? "rgba(0, 255, 0, 0.2)" : "rgba(0, 0, 255, 0.2)",
                                        width: 40,
                                        height: 40,
                                        borderRadius: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Ionicons
                                        name={"arrow-" + (transaction.type === WalletTransactionEnum.CREDIT ? "forward" : "back") as any}
                                        style={{
                                            color: transaction.type === WalletTransactionEnum.CREDIT ? "green" : "blue",
                                            fontSize: 20
                                        }}
                                    />
                                </View>
                                <View style={{ marginLeft: 10 }}>
                                    <Text style={{ fontFamily: "manropebold" }}>{transaction.type === WalletTransactionEnum.CREDIT ? "+" : "-"} &#8377; {transaction.amount}</Text>
                                    <Text style={[txtStyles.base, { color: "gray" }]}>{moment(transaction.created_at).format("DD/MM/YY, hh:mm a")}</Text>
                                </View>
                            </View>
                        ))
                    )}
                </View>
            </View>
        </ScrollView>
    )
}

export const WalletRecharge = () => {
    const [history, setHistory] = useContext(HistoryContext);
    const [storage, setStorage] = useContext(StorageContext);
    const [rechargeAmt, setRechargeAmt] = useState<number>(null);
    const recharges = [200, 500, 1000, 2000, 5000]
    const [upiId, setUpiId] = useState<string>(null);
    return (
        <ScrollView contentContainerStyle={{ padding: 20, display: "flex", alignItems: "center" }}>
            <Text style={[txtStyles.largeText, { marginBottom: 10 }]}>
                Recharge Wallet
            </Text>
            <Text style={[txtStyles.base, { marginTop: 20 }]}>
                Choose recharge amount
            </Text>
            <View style={{ marginTop: 20, width: "100%" }}>
                {recharges.map((recharge, index) => {
                    return <TouchableOpacity
                        key={index}
                        style={[styles.rechargeOption, rechargeAmt === recharge ? styles.rechargeOptionSelected : {}]}
                        onPress={() => {
                            setRechargeAmt(recharge);
                        }}
                    >
                        <Text style={[pageStyles.buttonText, { color: "black" }]}>₹ {recharge}</Text>
                    </TouchableOpacity>
                })}
            </View>
            <TouchableOpacity
                disabled={!rechargeAmt}
                style={[pageStyles.button, { backgroundColor: rechargeAmt ? "orange" : "gray", width: "100%", marginTop: 20 }]}
                onPress={() => {
                    setStorage({
                        ...storage,
                        modal: null
                    })
                    setHistory([...history, {
                        page: "CONFIRMATION",
                        meta: {
                            paymentMethod: "UPI",
                            upi_id: upiId,
                            entityType: EntityType.WALLET,
                            amount: rechargeAmt
                        }
                    }])
                }}
            >
                <Text style={pageStyles.buttonText}>Proceed</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

const WalletRechargeCash = () => {
    const recharges = [200, 500, 1000, 2000, 5000]
    const [storage, setStorage] = useContext(StorageContext);

    return (
        <View style={{ padding: 20, display: "flex", alignItems: "center" }}>
            <Text style={[txtStyles.largeText, { marginBottom: 10 }]}>
                Cash Recharge
            </Text>
            <Text
                style={[txtStyles.base, { marginTop: 10, textAlign: "center" }]}
            >
                Please head to the counter and ask for a wallet recharge of any of the following amounts - ₹{recharges.join(", ₹")}
            </Text>
            <Text
                style={[txtStyles.base, { marginTop: 10, marginBottom: 20, textAlign: "center" }]}
            >
                Make sure you have the exact amount with you in cash.
            </Text>
            <QRCode
                value={storage.user.username}
                size={250}
            />
            <View
                style={{
                    marginTop: 20,
                }}
            />
            <Text style={[txtStyles.largeText]}>
                {storage.user.username}
            </Text>
        </View>
    )
}


const styles = StyleSheet.create({
    rechargeOption: {
        padding: 20,
        backgroundColor: "white",
        borderRadius: 15,
        marginBottom: 10,
        color: "black",
        borderColor: "#e2e2e2",
        borderWidth: 1,
    },
    rechargeOptionSelected: {
        backgroundColor: "rgba(255,165,0,0.2)",
        borderColor: "rgb(255,165,0)",
    }
});