import { Animated, StatusBar, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { Ionicons } from '@expo/vector-icons';
import { ReactNode, useEffect, useRef } from "react";

export default function Header(props: {
    title: string,
    leftIcon?: string,
    rightIcon?: string,
    leftIconPress?: () => void,
    rightIconPress?: () => void
}) {

    const { title, leftIcon, rightIcon, leftIconPress, rightIconPress } = props;

    return (
        <View style={styles.header}>
            <TouchableOpacity onPress={leftIconPress} style={styles.button}>
                {leftIcon && <Ionicons name={leftIcon as any} style={styles.icon} />}
            </TouchableOpacity>
            <Text style={styles.headerText}>

                {title}
            </Text>
            <TouchableOpacity onPress={rightIconPress} style={styles.button}>
                {rightIcon && <Ionicons name={rightIcon as any} style={styles.icon} />}
            </TouchableOpacity>
        </View>
    )
}

export function PageHeader(props: { scrolled: number, title: string | ReactNode, rightElement?: ReactNode }) {
    const { scrolled, title, rightElement } = props;
    const minFontSize = 20;
    const maxFontSize = 30;
    const fontDifference = maxFontSize - minFontSize;
    const maxScroll = 100;
    const minScroll = 0;
    const scrollDifference = maxScroll - minScroll;
    const scrollPercent = (scrolled < maxScroll ? scrolled : maxScroll) / scrollDifference;
    const fontSize = maxFontSize - (fontDifference * scrollPercent);

    const fontAnimation = useRef(new Animated.Value(maxFontSize)).current;

    const translation = fontAnimation.interpolate({
        inputRange: [100, 130],
        outputRange: [maxFontSize, minFontSize],
        extrapolate: 'clamp',
    });

    return (
        <View style={[styles.headerFixed, { backgroundColor: scrolled > 0 ? "white" : "transparent" }]}>
            <Animated.Text
                style={[styles.headerText, {
                    fontSize: translation,
                }]}
            >
                {title}
            </Animated.Text>
            <View>
                {rightElement}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        width: "100%",
        height: 50,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerFixed: {
        top: 0,
        left: 0,
        right: 0,
        position: "absolute",
        zIndex: 100,
        padding: 15,
        paddingTop: StatusBar.currentHeight + 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    headerText: {
        color: "black",
        fontSize: 20,
        fontFamily: "manropebold",
    },
    icon: {
        color: "black",
        fontSize: 25,
    },
    button: {
        height: 45,
        width: 45,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
})